<template>
  <v-form>
    <v-stepper
      v-model.number="currentStepNumber"
      non-linear
      class="elevation-1 rounded-0"
    >
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :complete="currentStepNumber > 1"
          :rules="[() => validationStatus(1)]"
          editable
        >
          {{ $t("forms.event.event") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          :complete="currentStepNumber > 2"
          :rules="[() => validationStatus(2)]"
          editable
        >
          {{ $t("forms.event.organizer") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="3"
          :complete="currentStepNumber > 3"
          :rules="[() => validationStatus(3)]"
          editable
        >
          {{ $t("forms.event.heats") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="4"
          :complete="currentStepNumber > 4"
          :rules="[() => validationStatus(4)]"
          editable
        >
          {{ $t("forms.event.registration") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="5" :rules="[() => validationStatus(5)]" editable>
          {{ $t("forms.event.timingSetup") }}
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <component
      v-for="(step, index) in steps"
      :key="step"
      v-show="index === currentStepNumber - 1"
      :ref="`step-${index + 1}`"
      :is="step"
      :wizard-data="form"
      :current-step-number="currentStepNumber"
      @dateChanged="calculateDates"
    ></component>

    <v-alert v-if="error" class="ma-4" dense outlined type="error">
      {{ error }}
    </v-alert>

    <v-alert v-if="success" class="ma-4" dense outlined type="success">
      {{ success }}
    </v-alert>

    <div class="d-flex justify-space-between pa-4">
      <v-btn :disabled="loading" @click="backButtonAction">
        {{ isFirstStep ? $t("forms.cancel") : $t("forms.back") }}
      </v-btn>

      <v-btn color="primary" :loading="loading" @click="nextButtonAction">
        {{ isLastStep ? $t(submitText) : $t("forms.continue") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapState } from "vuex";
import cuid from "cuid";
import moment from "moment";
import FormGeneral from "./FormGeneral";
import FormOrganizer from "./FormOrganizer";
import FormHeats from "./FormHeats";
import FormRegistration from "./FormRegistration";
import FormSetup from "./FormSetup";
import deviceTypes from "@/utils/deviceTypes";

export default {
  name: "FormWizard",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    success: {
      type: String,
      default: null
    },
    submitText: {
      type: String,
      default: "forms.create"
    }
  },
  components: {
    FormGeneral,
    FormOrganizer,
    FormHeats,
    FormRegistration,
    FormSetup
  },
  data() {
    return {
      currentStepNumber: 1,
      steps: [
        "FormGeneral",
        "FormOrganizer",
        "FormHeats",
        "FormRegistration",
        "FormSetup"
      ],
      form: {
        event: {
          name: this.$i18n.t("forms.event.newEvent"),
          place: this.$i18n.t("forms.event.eventLocation"),
          country: null,
          date: {
            multiDay: false,
            from: {
              showDatePicker: false,
              timestamp: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
              ).getTime()
            },
            to: {
              showDatePicker: false,
              timestamp: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + 1
              ).getTime()
            }
          },
          image: {
            file: null,
            loading: false
          },
          showDatePicker: false,
          publicResult: false,
          registration: {
            public: false,
            from: {
              showDatePicker: false,
              showTimePicker: false,
              timestamp: Date.now()
            },
            to: {
              showDatePicker: false,
              showTimePicker: false,
              timestamp: new Date(
                new Date().setDate(new Date().getDate() + 1)
              ).getTime()
            }
          }
        },
        heats: {
          rankingBestOf: {
            enabled: false,
            count: 0
          },
          dates: []
        },
        organizer: {
          name: null,
          website: null,
          image: {
            file: null,
            data: null,
            loading: false
          }
        },
        registration: {
          data: [
            {
              id: "startNumber",
              type: "INTEGER",
              view: {
                label: "Start Number"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: false
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: true,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "lastName",
              type: "STRING",
              view: {
                label: "Last Name",
                format: "DEFAULT"
              },
              validation: {
                required: true
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: true
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: true,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "firstName",
              type: "STRING",
              view: {
                label: "First Name",
                format: "DEFAULT"
              },
              validation: {
                required: true
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: true
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: true,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "gender",
              type: "STRING",
              view: {
                label: "Gender"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: true
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: false,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "dateOfBirth",
              type: "DATE",
              view: {
                label: "Date of Birth"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: true
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: false,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "nation",
              type: "STRING",
              view: {
                label: "Nation"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: true
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: true,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "club",
              type: "STRING",
              view: {
                label: "Club"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: false
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: false,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "team",
              type: "STRING",
              view: {
                label: "Team"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: false
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: false,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "class",
              type: "STRING",
              view: {
                label: "Class"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: false
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: false,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            },
            {
              id: "email",
              type: "STRING",
              view: {
                label: "Email"
              },
              validation: {
                required: false
              },
              accessControl: {
                competitor: {
                  read: true,
                  write: true
                },
                owner: {
                  read: true,
                  write: true
                },
                other: {
                  read: false,
                  write: false
                }
              },
              settings: {
                classDivision: false
              }
            }
          ]
        },
        timingSetup: {
          precision: "1/100s",
          rounding: "CUT_OFF",
          speedPrecision: "1/100u",
          speedRounding: "CUT_OFF",
          runningTime: true,
          sectionTimeRanking: true,
          splitTimeRanking: true,
          speedRanking: true,
          speedEvent: false,
          measuringPoints: [],
          massStartFieldId: null,
          regularity: "DISABLED"
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      balance: state => state.timingpoints.balance
    }),
    currentStep() {
      return this.steps[this.currentStepNumber - 1];
    },
    isFirstStep() {
      return this.currentStepNumber === 1;
    },
    isLastStep() {
      return this.currentStepNumber === this.steps.length;
    },
    isValid() {
      let valid = true;

      for (let i = 0; i < this.steps.length; i++) {
        valid = valid && !this.$refs[`step-${i + 1}`][0].$v.$invalid;
      }

      return valid;
    },
    isDirty() {
      let dirty = false;

      for (let i = 0; i < this.steps.length; i++) {
        dirty = dirty || this.$refs[`step-${i + 1}`][0].$v.$anyDirty;
      }

      return dirty;
    }
  },
  watch: {
    currentStepNumber(newStepNumber, oldStepNumber) {
      this.$refs[`step-${oldStepNumber}`][0]
        .submit()
        .then(stepData => {
          Object.assign(this.form[stepData.step], stepData.form);
        })
        .catch(stepData => {
          Object.assign(this.form[stepData.step], stepData.form);
        });
    }
  },
  methods: {
    validationStatus(stepNumber) {
      if (this.$refs[`step-${stepNumber}`] === undefined) return true;

      const validation = this.$refs[`step-${stepNumber}`][0].$v;
      return !validation.$dirty || !validation.$invalid;
    },
    backButtonAction() {
      if (this.isFirstStep) {
        this.$router.push({ name: "Events" });
      } else {
        this.currentStepNumber -= 1;
      }
    },
    nextButtonAction() {
      if (this.isLastStep) {
        this.error = null;

        this.$refs[`step-${this.steps.length}`][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form[stepData.step], stepData.form);

            for (let i = 0; i < this.steps.length; i++) {
              this.$refs[`step-${i + 1}`][0].submit().catch(stepData => {
                console.error(`${stepData.step} is invalid`);
              });
            }

            if (this.isValid) {
              this.submitForm();
            } else {
              this.error = this.$i18n.t("forms.event.errors.formNotComplete");
            }
          })
          .catch(stepData => {
            Object.assign(this.form[stepData.step], stepData.form);

            for (let i = 0; i < this.steps.length; i++) {
              this.$refs[`step-${i + 1}`][0].submit().catch(stepData => {
                console.error(`${stepData.step} is invalid`);
              });
            }

            if (this.isValid) {
              this.submitForm();
            } else {
              this.error = this.$i18n.t("forms.event.errors.formNotComplete");
            }
          });
      } else {
        this.currentStepNumber += 1;
      }
    },
    submitForm() {
      const form = {
        event: {
          country: this.form.event.country,
          date: {
            multiday: this.form.event.date.multiDay,
            from: moment(this.form.event.date.from.timestamp).format(
              "YYYY-MM-DD"
            ),
            to: moment(this.form.event.date.to.timestamp).format("YYYY-MM-DD")
          },
          image: this.form.event.image.data,
          name: this.form.event.name,
          place: this.form.event.place,
          registration: {
            public: this.form.event.registration.public,
            from: new Date(
              this.form.event.registration.from.timestamp
            ).toISOString(),
            to: new Date(
              this.form.event.registration.to.timestamp
            ).toISOString()
          },
          publicResult: this.form.event.publicResult
        },
        heats: [],
        rankingSettings: {
          rankBestOfCount: this.form.heats.rankingBestOf.enabled
            ? this.form.heats.rankingBestOf.count
            : 0
        },
        organizer: {
          organizerImage: this.form.organizer.image.data,
          organizerName: this.form.organizer.name,
          organizerWebsite: this.form.organizer.website
        },
        registration: this.form.registration,
        timingSetup: {
          precision: this.form.timingSetup.precision,
          rounding: this.form.timingSetup.rounding,
          speedPrecision: this.form.timingSetup.speedPrecision,
          speedRounding: this.form.timingSetup.speedRounding,
          runningTime: this.form.timingSetup.runningTime,
          speedUnit: this.form.timingSetup.speedUnit,
          useTolerances: this.form.timingSetup.useTolerances,
          speedEvent: this.form.timingSetup.speedEvent,
          measuringPoints: [],
          regularitySettings: {
            enabled: this.form.timingSetup.regularity !== "DISABLED",
            type:
              this.form.timingSetup.regularity === "DISABLED"
                ? "TARGET_TIME"
                : this.form.timingSetup.regularity
          }
        },
        massStartSettings: {
          massStartFieldId: this.form.timingSetup.massStartFieldId
        }
      };

      this.form.heats.dates.forEach(date => {
        date.items.forEach(heat => {
          form.heats.push({
            name: heat.name,
            timestamp: new Date(heat.startTime).toISOString(),
            excludeFromOverallRanking: !heat.isRated
          });
        });
      });

      this.form.timingSetup.measuringPoints.forEach((mp, mpIndex) => {
        if (mpIndex === 0) {
          form.timingSetup.measuringPoints.push({
            devices: []
          });
        } else {
          let minSpeed = mp.speedMeasurementMinSpeed;
          let maxSpeed = mp.speedMeasurementMaxSpeed;

          switch (this.form.timingSetup.speedUnit) {
            case "KM_H":
              minSpeed /= 3.6;
              maxSpeed /= 3.6;
              break;
            case "MP_H":
              minSpeed /= 2.23694;
              maxSpeed /= 2.23694;
              break;
            default:
              break;
          }

          form.timingSetup.measuringPoints.push({
            devices: [],
            tolerance: {
              lowerBound: mp.toleranceLowerBound,
              isLowerPercentage: mp.toleranceIsLowerPercentage,
              upperBound: mp.toleranceUpperBound,
              isUpperPercentage: mp.toleranceIsUpperPercentage
            },
            speedMeasurement: {
              distance: mp.speedMeasurementEnabled
                ? mp.speedMeasurementDistance * 1000
                : 0,
              minSpeed: mp.speedMeasurementEnabled ? minSpeed : 0,
              maxSpeed: mp.speedMeasurementEnabled ? maxSpeed : 0
            },
            sectionRankingSettings: {
              sectionTimeRanking: this.form.timingSetup.sectionTimeRanking,
              splitTimeRanking: this.form.timingSetup.splitTimeRanking,
              speedRanking: this.form.timingSetup.speedRanking
            }
          });
        }

        mp.devices.forEach((item, itemIndex) => {
          form.timingSetup.measuringPoints[mpIndex].devices.push({
            id: item.deviceId,
            channel: deviceTypes["MT1"].channels[item.channelIndex].name
          });
        });
      });

      this.$emit("formSubmitted", form);
    },
    calculateDates() {
      return new Promise((resolve, reject) => {
        let dates = [];

        if (!this.form.event.date.from.timestamp) {
          return;
        }

        let startDate = new Date(
          new Date(this.form.event.date.from.timestamp).getFullYear(),
          new Date(this.form.event.date.from.timestamp).getMonth(),
          new Date(this.form.event.date.from.timestamp).getDate()
        );

        if (
          this.form.event.date.multiDay &&
          this.form.event.date.to.timestamp &&
          this.form.event.date.from.timestamp <
            this.form.event.date.to.timestamp
        ) {
          dates.push({
            date: startDate.getTime(),
            items: []
          });

          while (
            startDate.getTime() !==
            new Date(
              new Date(this.form.event.date.to.timestamp).getFullYear(),
              new Date(this.form.event.date.to.timestamp).getMonth(),
              new Date(this.form.event.date.to.timestamp).getDate()
            ).getTime()
          ) {
            startDate.setDate(startDate.getDate() + 1);
            dates.push({
              date: startDate.getTime(),
              items: []
            });
          }
        } else {
          dates.push({
            date: startDate.getTime(),
            items: []
          });
        }

        dates = dates.map((date, dateIndex) => {
          if (this.form.heats.dates[dateIndex]) {
            const heatItem = this.form.heats.dates[dateIndex];
            heatItem.date = date.date;
            heatItem.items.map(item => {
              const curTime = new Date(item.startTime);
              const newDate = new Date(heatItem.date);

              item.startTime = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate(),
                curTime.getHours(),
                curTime.getMinutes()
              ).getTime();
            });

            return heatItem;
          }

          return date;
        });

        this.form.heats.dates = dates;
        resolve();
      });
    },
    initForm() {
      // event
      this.form.event.country = this.user.address.country;

      // organizer
      this.form.organizer.name = `${this.user.lastname} ${this.user.firstname}`;

      // heats
      this.calculateDates();
      this.$nextTick(() => this.$refs["step-3"][0].addHeatToDate(0));

      // timingSetup
      for (let i = 0; i < 2; i++) {
        this.form.timingSetup.measuringPoints.push({
          id: cuid(),
          devices: [],
          toleranceLowerBound: 10,
          toleranceIsLowerPercentage: true,
          toleranceUpperBound: 20,
          toleranceIsUpperPercentage: true,
          speedMeasurementEnabled: false,
          speedMeasurementDistance: 5,
          speedMeasurementMinSpeed: 10,
          speedMeasurementMaxSpeed: 150
        });
      }
    }
  },
  created() {
    this.initForm();
  }
};
</script>
