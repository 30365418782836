<template>
  <v-card>
    <v-card-title>{{ $t("events.createEvent") }}</v-card-title>
    <FormWizard
      ref="wizard"
      :loading="loading"
      :error="errorMessage"
      :success="successMessage"
      @formSubmitted="submitEvent"
    />

    <Confirmation ref="dialog">
      <v-card-text>
        {{ $t("forms.confirmation.discardText") }}
      </v-card-text>
    </Confirmation>
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import FormWizard from "@/components/forms/event/FormWizard";
import Confirmation from "@/components/Confirmation";

export default {
  name: "PanelEventsCreate",
  components: {
    FormWizard,
    Confirmation
  },
  data() {
    return {
      loading: false,
      error: null,
      success: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    errorMessage() {
      return this.$i18n.t(this.error);
    },
    successMessage() {
      return this.$i18n.t(this.success);
    }
  },
  methods: {
    ...mapActions({
      createEvent: "events/createEvent"
    }),
    submitEvent(form) {
      this.error = null;
      this.loading = true;

      const payload = {
        userId: this.user.id,
        data: form
      };

      this.createEvent(payload)
        .then(response => {
          this.loading = false;
          this.success = "forms.event.createSuccess";
          this.$router.push({ name: "Events" });
        })
        .catch(response => {
          switch (response.data.status) {
            case -3009:
              this.error = "forms.event.errors.notEnoughTPs";
              break;
            case -7000:
              this.error = "forms.event.errors.invalidImageFormat";
              break;
            default:
              this.error = "errors.unexpectedError";
          }
          this.loading = false;
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.wizard.isDirty && !this.success) {
      this.$refs.dialog.confirm()
        .then(response => {
          if (response) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  }
};
</script>
